export const Endpoints = {
  login: "/users/sign_in",
  loginType: "/auth/type",
  loginRedeem: "/auth/redeemcode",
  products: "/api/products",
  efris_products: "/api/products/efris",
  productGroups: "/api/productgroups",
  accounts: "/api/accounts",
  invoices: "/api/invoices",
  stockrecords: "/api/stockrecords",
  receipts: "/api/receipts",
  memos: "/api/memos",
  vouchers: "/api/vouchers",
  defaults: "/api/invoices/defaults",
  companies: "/api/companies",
  currencies: "/api/currencies",
  taxes: "/api/taxes",
  whduties: "/api/whduties",
  locations: "/api/locations",
  countries: "/api/countries",
  banks: "/api/banks",
  schoolStudentCategories: "/api/school/studentcategories",
  subjectteachers: "/api/school/subjectteachers",
  classteachers: "/api/school/classteachers",
  tenants: "/api/tenants",
  home: "/api/home/mustard",
  subjects: "/api/school/subjects",
  marktypes: "/api/school/marktypes",
  marks: "/api/school/marks",
  attendances: "/api/school/attendances",
  marksGrid: "/api/school/marks/grid",
  bulkDeleteMarks: "/api/school/marks/bulkdelete",
  terminalreport: "/api/school/terminalreport",
  progressreport: "/api/school/progressreport",
  cummulativereport: "/api/school/cummulativereport",
  ncterminalreport: "/api/school/ncterminalreport",
  markSheet: "/api/school/marks/marksheet",
  avgMarkSheet: "/api/school/marks/avgmarksheet",
  ncMarkSheet: "/api/school/marks/ncmarksheet",
  remarktypes: "/api/school/remarktypes",
  otherstats: "/api/school/otherstatistics",
  resultanalysis: "/api/school/analysis",
  students: "/api/students",
  applications: "/api/students/application",
  applicant: "/api/students/applicant",
  posts: "/api/posts",
  promoteStudents: "/api/student/promote",
  grades: "/api/school/grades",
  proportions: "/api/school/proportions",
  alevelscores: "/api/school/alevelscores",
  automarkcomments: "/api/school/automarkcomments",
  markcomments: "/api/school/markcomments",
  genericcomments: "/api/school/genericcomments",
  remarks: "/api/school/remarks",
  publishmarks: "/api/school/publishmarks",
  removeRemarks: "/api/remark/delete",
  autoreportcomments: "/api/school/autoreportcomments",
  userProfile: "/api/users/profile",
  topics: "/api/school/topics",
  getSchoolFeesDefaults: "/api/school/fees/defaults",
  projects: "/api/school/projects",
  projectScores: "/api/school/projectscores",
  marktargets: "/api/school/marktargets",
  users: "/api/users",
  deputies: "/api/school/deputies",
  requistionsDefaults: "/api/requisitions/defaults",
  gradefoots: "/api/school/gradefoots",
  reportFooters: "/api/school/reportfooters",
  subjectPapers: "/api/school/subjectpapers",
};
