import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../components/common/Loading";

import { Button, Col, Container, Row, Spinner } from "reactstrap";

import { Form, Formik } from "formik";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import { XFileInput } from "../../components/common/inputs/XFileInput";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { XTextArea } from "../../components/common/inputs/XTextArea";
import { XSelect } from "../../components/common/inputs/XSelect";
import { getForms } from "../../utils/Helpers";
import { XDatePickerField } from "../../components/common/inputs/XDatePickerField";

const BulkUpload = (props: any) => {

  const terms = [
    { value: 'I', text: 'I' },
    { value: 'II', text: 'II' },
    { value: 'III', text: 'III' }
  ]

  const genders = [
    { value: 'FEMALE', text: 'FEMALE' },
    { value: 'MALE', text: 'MALE' }
  ]

  const campuses = [
    { value: 'Kisubi', text: 'Kisubi' },
    { value: 'Kikusa', text: 'Kikusa' }
  ]

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Formik
            initialValues={props.initialValues}
            enableReinitialize
            validateOnBlur
            onSubmit={(values: any) => props.handleSubmit(values)}
          >
            {({ isSubmitting, setFieldValue, values, errors, touched }) => (
              <>
                <Form>
                  <Row>
                    <Col lg={6}>
                      <XFileInput
                        key="file"
                        id="photo"
                        name={"photo"}
                        label="Passport photo"
                        accept="image/png, image/jpeg"
                        onChange={
                          (event: any) => {
                            setFieldValue('photo', event.currentTarget.files[0]);
                          }
                        }
                        errors={errors}
                      />
                    </Col>
                    <Col lg={6}>
                      <XFileInput
                        key="file"
                        id="results"
                        name={"results"}
                        label="Recent results"
                        onChange={
                          (event: any) => {
                            setFieldValue('results', event.currentTarget.files[0]);
                          }
                        }
                        errors={errors}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <XSelect
                        key="form"
                        id="form"
                        name={"form"}
                        options={getForms()}
                        label={"Class"}
                        touched={touched}
                        errors={errors}
                      />
                    </Col>
                    <Col lg={3}>
                      <XSelect
                        key="term"
                        id="term"
                        name={"term"}
                        options={terms}
                        label={"Term"}
                        touched={touched}
                        errors={errors}
                      />
                    </Col>
                    <Col lg={3}>
                      <XTextBox
                        type="number"
                        name="year"
                        placeholder="Year"
                        id="year"
                        key={"year"}
                        label="Year"
                        touched={touched}
                        errors={errors}
                        size={{}}
                      />
                    </Col>

                    <Col lg={3}>
                      <XSelect
                        key="gender"
                        id="gender"
                        name={"gender"}
                        options={genders}
                        label={"Gender"}
                        touched={touched}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <XTextBox
                        type="text"
                        name="firstname"
                        placeholder="First name"
                        label="First name"
                        id="firstname"
                        key={"firstname"}
                        touched={touched}
                        errors={errors}
                        size={{}}
                      />
                    </Col>
                    <Col lg={6}>
                      <XTextBox
                        type="text"
                        name="lastname"
                        placeholder="Last name"
                        label="Last name"
                        id="lastname"
                        key={"lastname"}
                        touched={touched}
                        errors={errors}
                        size={{}}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <XTextBox
                        type="text"
                        name="student_nin"
                        placeholder="Student's NIN"
                        label="Student's NIN"
                        id="student_nin"
                        key={"student_nin"}
                        touched={touched}
                        errors={errors}
                        size={{}}
                      />
                    </Col>
                    <Col lg={6}>
                      <XTextBox
                        type="text"
                        name="parent_nin"
                        placeholder="Parent's NIN"
                        label="Parent's NIN"
                        id="parent_nin"
                        key={"parent_nin"}
                        touched={touched}
                        errors={errors}
                        size={{}}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <XTextBox
                        type="text"
                        name="phone"
                        placeholder="Contact Numbers"
                        label="Contact Numbers"
                        id="phone"
                        key={"phone"}
                        touched={touched}
                        errors={errors}
                        size={{}}
                      />
                    </Col>
                    <Col lg={6}>
                      <XTextBox
                        type="text"
                        name="email"
                        placeholder="Contact Email"
                        label="Contact Email"
                        id="email"
                        key={"email"}
                        touched={touched}
                        errors={errors}
                        size={{}}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3}>
                      <XDatePickerField
                        key="dob"
                        id="dob"
                        name={"dob"}
                        label={"Date of Birth"}
                        touched={touched}
                        onChange={setFieldValue}
                        value={values.dob}
                        errors={errors}
                      />
                    </Col>
                    <Col lg={3}>
                      <XTextBox
                        type="text"
                        name="house"
                        placeholder="House"
                        label="House"
                        id="house"
                        key={"house"}
                        touched={touched}
                        errors={errors}
                        size={{}}
                      />
                    </Col>
                    <Col lg={3}>
                      <XSelect
                        key="campus"
                        id="campus"
                        name={"campus"}
                        options={campuses}
                        label={"Campus"}
                        touched={touched}
                        errors={errors}
                      />
                    </Col>
                    <Col lg={2}>
                      <div>&nbsp;</div>
                      <Button
                        disabled={props.isLoading ? true : false}
                        type="submit"
                        className="btn btn-primary"
                      >
                        {props.isLoading && <Spinner></Spinner>} Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <hr />
              </>
            )}
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BulkUpload;
