import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";
import { getForms } from "../utils/Helpers";

const genders = [
  {value: 'F', text: 'Female'},
  {value: 'M', text: 'Male'}
]

const statuses = [
  {value: 'active', text: 'Active'},
  {value: 'inactive', text: 'Inactive'}
]

const forms = getForms();

export const SmsFields: IField[] = [
  { name: "sender", type: "text", label: "Sender", size: {lg: 12}, position: 1 },
  { name: "message", type: "textarea", label: "Message", size: {lg: 12}, position: 2 },
].sort((a: IField, b: IField) => a.position - b.position);