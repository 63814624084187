import { Col, Container, Row } from "reactstrap";

import React from "react";
import MetaTags from "react-meta-tags";

//Import Countdown
import Countdown from "react-countdown";

//import images
import logolight from "../../assets/images/logo-white.png";
import logoDark from "../../assets/images/logo-colored.svg";

import { Link } from "react-router-dom";


const Privacy = () => {

  return (
    <React.Fragment>
      <MetaTags>
        <title>Treda - React Admin & Dashboard Template</title>
      </MetaTags>
      <div className="container">
        <div>
          <h1>Privacy Policy for Greenhill Mobi</h1>
          <p><strong>Effective Date:</strong> 1st Nov 2024</p>

          <h2>1. Introduction</h2>
          <p>Welcome to Greenhill Mobi! Greenhill Mobi is a mobile application created by [Your Company/School Name] to help parents stay informed about their child’s school activities, grades, attendance, and other relevant updates. This privacy policy explains how we collect, use, and protect your information when you use Greenhill Mobi.</p>

          <h2>2. Information We Collect</h2>
          <p>We collect personal information to provide a better service experience and improve Greenhill Mobi. The types of information we collect include:</p>

          <h3>a. Personal Information</h3>
          <ul>
            <li><strong>Account Information:</strong> We collect basic information such as your name, email address, and phone number during registration.</li>
            <li><strong>Child Information:</strong> To enable parents to view their child’s school-related information, we may collect and store student data such as name, student ID, grades, attendance records, and other educational data.</li>
          </ul>

          <h3>b. Usage Information</h3>
          <ul>
            <li><strong>Device Information:</strong> We collect information about the device you use to access the app, such as your device model, operating system, and unique device identifiers.</li>
            <li><strong>Log Data:</strong> We collect data on how you interact with the app, including the pages accessed, date and time of access, and app performance metrics.</li>
          </ul>

          <h3>c. Optional Information</h3>
          <ul>
            <li><strong>Contact Information:</strong> If you reach out to us for support or feedback, we may collect additional information to address your concerns and improve our services.</li>
          </ul>

          <h2>3. How We Use Your Information</h2>
          <p>The information collected is used for the following purposes:</p>
          <ul>
            <li><strong>To Provide Services:</strong> We use your information to give you access to the app’s features, including viewing your child’s grades, attendance, and school notifications.</li>
            <li><strong>To Improve the App:</strong> Usage data helps us improve app functionality, monitor performance, and troubleshoot issues.</li>
            <li><strong>To Communicate with You:</strong> We use contact information to send you important updates, notifications, and respond to your inquiries.</li>
            <li><strong>For Legal Compliance:</strong> We may use your information to comply with legal and regulatory obligations.</li>
          </ul>

          <h2>4. Sharing Your Information</h2>
          <p>We prioritize your privacy and do not sell or share your information with third parties, except in the following cases:</p>
          <ul>
            <li><strong>With School Administrators:</strong> We share relevant information with your child’s school to ensure the accuracy of student records.</li>
            <li><strong>With Service Providers:</strong> We may work with third-party vendors for data storage, app analytics, and technical support. These providers are contractually obligated to protect your data.</li>
            <li><strong>For Legal Reasons:</strong> We may disclose your information if required by law or to protect our rights, property, or safety, or that of others.</li>
          </ul>

          <h2>5. Data Security</h2>
          <p>We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, please note that no security measures are completely foolproof, and we cannot guarantee the absolute security of your data.</p>

          <h2>6. Data Retention</h2>
          <p>We retain your personal information for as long as your account is active or as needed to provide services. When information is no longer needed, we securely delete or anonymize it according to applicable data retention policies.</p>

          <h2>7. Your Rights</h2>
          <p>Depending on your location, you may have the following rights concerning your personal information:</p>
          <ul>
            <li><strong>Access:</strong> Request a copy of your personal information.</li>
            <li><strong>Correction:</strong> Correct any inaccurate information.</li>
            <li><strong>Deletion:</strong> Request the deletion of your data, subject to legal and contractual limitations.</li>
            <li><strong>Opt-Out:</strong> Opt out of receiving marketing communications.</li>
          </ul>
          <p>To exercise any of these rights, please contact us at <strong>[contact email]</strong>.</p>

          <h2>8. Children’s Privacy</h2>
          <p>Greenhill Mobi is intended for use by parents and guardians only. We do not knowingly collect information from children under the age of 13. If we become aware of any unintentional data collection from children, we will promptly delete it.</p>

          <h2>9. Changes to This Privacy Policy</h2>
          <p>We may update this privacy policy periodically to reflect changes in our practices or legal requirements. We will notify you of significant changes by posting the updated policy within the app or through other communication methods.</p>

          <h2>10. Contact Us</h2>
          <p>If you have any questions about this privacy policy or our data practices, please contact us at:</p>
          <p><strong>Greenhill Academy</strong><br />
            <strong>Address:</strong> Kibuli Campus | Mbogo Road Kibuli, Kampala<br />
            <strong>Email:</strong> info@greenhillacademy.ac.ug<br />
            <strong>Phone:</strong> +256 41 4342684</p>
        </div>

      </div>
    </React.Fragment>
  );
};

export default Privacy;
