import resolveBaseUrl from "../services";

const baseUrl = resolveBaseUrl();

const getFile = (fileName) => {
    return fileName.startsWith("http") ? fileName : `${baseUrl}/${fileName}`;
};

export const Settings = {
    getFile
};