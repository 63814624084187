import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from "reactstrap";
import { Settings } from "../../utils/Settings";


const Student = (props: any) => {
  const { data } = props;

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-transparent">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h5 className="card-title mb-0">Students</h5>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {data.map((student: any) => {
            return (
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={4} className="avatar-wrapper">
                      <img src={Settings.getFile(student.avatarUrl)} />
                    </Col>
                    <Col lg={8}>
                      <p><b>{student.name}</b></p>
                      <p>
                      <div>{student.studentid} - {student.form} {student.stream} </div>
                      <div>{student.campus} </div>
                      </p>
                      <p><i className="fa fa-home"></i> Attendance &nbsp;&nbsp;&nbsp; <i className="fa fa-check-square"></i> {student.balance}</p>
                      <div>
                        <a href={`/students/${student.id}`}>Results</a>
                        &nbsp;| <a href={`/students/${student.id}`}>Financial</a>
                        &nbsp;| <a href={`/students/${student.id}`}>Comments</a>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )
          })}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Student;
