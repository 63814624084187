import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../components/common/Loading";

import { Button, Col, Container, Row, Spinner } from "reactstrap";

import { Form, Formik } from "formik";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import { XFileInput } from "../../components/common/inputs/XFileInput";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { XTextArea } from "../../components/common/inputs/XTextArea";

const BulkUpload = (props: any) => {

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Formik
            initialValues={props.initialValues}
            enableReinitialize
            validateOnBlur
            onSubmit={(values: any) => props.handleSubmit(values)}
          >
            {({ isSubmitting, setFieldValue, values, errors, touched }) => (
              <>
                <Form>
                  <Row>
                    <Col lg={4}>
                      <XFileInput
                        key="file"
                        id="cover"
                        name={"cover"}
                        placeholder="Cover Image"
                        accept="image/png, image/jpeg"
                        onChange={
                          (event: any) => {
                            setFieldValue('cover', event.currentTarget.files[0]);
                          }
                        }
                        errors={errors}
                      />
                    </Col>
                    <Col lg={8}>
                      <XTextBox
                        key="title"
                        id="title"
                        name={"title"}
                        type="text"
                        placeholder={"Title"}
                        touched={touched}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <XTextArea
                        name="content"
                        placeholder="Content"
                        id="content"
                        key={"content"}
                        rows="4"
                        touched={touched}
                        errors={errors}
                        size={{}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2}>
                      <div>&nbsp;</div>
                      <Button
                        disabled={props.isLoading ? true : false}
                        type="submit"
                        className="btn btn-primary"
                      >
                        {props.isLoading && <Spinner></Spinner> } Upload
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <hr />
              </>
            )}
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BulkUpload;
