import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { Form, Formik } from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { Endpoints } from "../../utils/Endpoints";
import { ApiCore } from "../../helpers/api_helper";
import { ISelectOption } from "../../interfaces/inputs/ISelectOption";
import { XDatePickerField } from "../../components/common/inputs/XDatePickerField";
import { AxiosError, AxiosResponse } from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { getForms } from "../../utils/Helpers";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";

const SearchMarks = (props: any) => {
  const [defaults, setDefaults] = useState<any>({});
  const [studentcategories, setStudentcategories] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const params = new URLSearchParams(useLocation().search);
  const invtype = params.get("invtype") ? params.get("invtype") : "sale";

  const history = useHistory();

  const request = new ApiCore();

  const invoiceTypes: ISelectOption[] = [
    { value: "purchase", text: "Purchase" },
    { value: "sale", text: "Sale" },
  ];

  let initialValues: any = {
    term: "",
    form: "",
    name: "",
    year: ""
  };

  const handleSubmit = (values: any) => {
    props.handleSearch(values);
  };

  const terms = [
    {value: 'I', text: 'I'},
    {value: 'II', text: 'II'},
    {value: 'III', text: 'III'}
  ]

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {loading && <Loading label="Loading students. Please wait." />}
          {!loading && (
            <>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={2}>
                          <XSelect
                            key="form"
                            id="form"
                            name={"form"}
                            options={getForms()}
                            label={"Class"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XSelect
                            key="term"
                            id="term"
                            name={"term"}
                            options={terms}
                            label={"Term"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <XTextBox
                            type="text"
                            key="year"
                            id="year"
                            name={"year"}
                            label={"Year"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XTextBox
                            type="text"
                            key="name"
                            id="name"
                            name={"name"}
                            label={"Name"}
                            touched={touched}
                            value={values.name}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XTextBox
                            type="text"
                            key="studentid"
                            id="studentid"
                            name={"studentid"}
                            label={"Student ID"}
                            touched={touched}
                            value={values.studentid}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <div>&nbsp;</div>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr/>
                  </>
                )}
              </Formik>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchMarks;
