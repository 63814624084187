import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";
import { getForms } from "../utils/Helpers";

const genders = [
  {value: 'F', text: 'Female'},
  {value: 'M', text: 'Male'}
]

const statuses = [
  {value: 'active', text: 'Active'},
  {value: 'inactive', text: 'Inactive'}
]

const forms = getForms();

export const AdmissionFields: IField[] = [
  { name: "reporting_date", type: "text", label: "Date", size: {lg: 6}, position: 1 },
  { name: "headteacher_name", type: "text", label: "Head teacher's name", size: {lg: 6}, position: 4 },
  { name: "headteacher_title", type: "text", label: "Head teacher's title", size: {lg: 6}, position: 5 }
].sort((a: IField, b: IField) => a.position - b.position);