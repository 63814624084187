import { Col, Label } from "reactstrap";
import { IFileInputProps } from "../../../interfaces/inputs/IFileInputProps";

export const XFileInput = (props: IFileInputProps) => {
  const hasErrors = props.errors && props.errors[props.name];
  return (
    <Col {...props.size}>
      <div className={`mb-3 ${props.icon ? "form-floating form-floating-custom" : ""}`}>

        {!props.icon && props.label &&
          <Label className={hasErrors && "text-danger"}
                 htmlFor={props.id}>{props.label}</Label>
        }
        <input
          id={props.id}
          name={props.name}
          type="file"
          className={`form-control ${hasErrors && "is-invalid"}`}
          placeholder={props.placeholder ? props.placeholder : ""}
          onChange={(event) => {
            if(props.onChange) {
              props.onChange(event);
            }
          }}
          accept={props.accept ? props.accept : ""}
          multiple={props.multiple}
        />  

        {props.icon &&
          <>
            <Label className={hasErrors && "text-danger"}
                   htmlFor={props.id}>{props.label}</Label>
            <div className="form-floating-icon">
              <i className={`fa ${props.icon} ${hasErrors && "text-danger"}`}></i>
            </div>
          </>
        }
      </div>
    </Col>
  );
};