interface MenuItemsProps {
  id: number;
  novidade?: any;
  label: string;
  icon?: string;
  link?: string;
  badge?: string;
  badgecolor?: string;
  businessCategory?: string;
  subItems?: any;
  isHeader?: boolean;
  clazz?: string;
  ability?: string;
}

const menuItems: Array<MenuItemsProps> = [
  {
    id: 139,
    label: "Dashboard",
    icon: "chart-line",
    link: "/dashboard",
  },
  {
    id: 1,
    label: "General",
    isHeader: true,
  },
  {
    id: 138,
    label: "Students",
    icon: "users",
    link: "/students/list",
  },
  {
    id: 143,
    label: "Noticeboard",
    icon: "calendar",
    link: "/posts"
  },
  {
    id: 144,
    label: "Admissions",
    icon: "plus-square",
    link: "/applications"
  },
  {
    id: 1,
    label: "Settings",
    isHeader: true,
  },
  {
    id: 140,
    label: "Grades",
    icon: "th",
    link: "/grades",
  },
];

export { menuItems };
