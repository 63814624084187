import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { Endpoints } from "../../utils/Endpoints";
import { StudentFields } from "../../fields/StudentFields";
import SearchMarks from "./search";
import BulkUpload from "./upload";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { can } from "../../utils/Helpers";
import { Link, useHistory } from "react-router-dom";
import { SmsFields } from "../../fields/SmsFields";
import { InterviewFields } from "../../fields/InterviewFields";
import { AdmissionFields } from "../../fields/AdmissionFields";

const StudentApplications = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [students, setStudents] = useState<any>([]);
  const [student, setStudent] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<any>([]);
  const [showStudentDialog, setShowStudentDialog] = useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [showSmsDialog, setShowSmsDialog] = useState<boolean>(false);
  const [showInterviewDialog, setShowInterviewDialog] = useState<boolean>(false);
  const [showAdmissionDialog, setShowAdmissionDialog] = useState<boolean>(false);
  const history = useHistory();
  const itemsPerPage = 50;
  const [initialValues, setInitialValues] = useState<any>({
    firstname: "",
    lastname: ""
  });

  // State to keep track of selected rows
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  // Function to handle individual row selection
  const handleRowSelection = (id: any) => {
    setSelectedRows((prevSelected: any) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId: any) => rowId !== id) // Deselect if already selected
        : [...prevSelected, id] // Select if not already selected
    );
  };

  // Function to handle "Select All"
  const handleSelectAll = (type: string) => {
    if (type == "select") {
      setSelectedRows(students.map((row: any) => row.id));
    } else {
      setSelectedRows([]);
    }
  };

  // Check if all rows are selected
  const isAllSelected = selectedRows.length === students.length;

  const request = new ApiCore();

  useEffect(() => {
    searchStudents({})
  }, []);

  const handleUpdate = (item: any) => {
    const items = students.map((i: any) => {
      if (i.id === item.id) {
        return { ...i, ...item };
      }

      return i;
    })
    setStudents(items);
  };

  const handleSelectedActions = (values: any) => {
    const responseType = "arraybuffer";
    toast.promise(request.createFormData(Endpoints.applicant, values, responseType), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      if (values.type == "interview" || values.type == "admission" || values.type == "list") {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `mustard-${+ new Date()}.pdf`
        link.click()
      }
    });
  }

  const searchStudents = (values: any) => {
    values.per_page = 1000;
    request
      .get(Endpoints.applications, { params: values }, values.excel && values.excel == 1 ? "arraybuffer" : "json").then((response: AxiosResponse) => {
        setStudents(response.data);
        setTotalRecords(response.data.length);
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  }

  const handleBulkUpload = (values: any) => {
    const responseType = "arraybuffer";
    setSaving(true)
    request.createFormData(Endpoints.applications, values, responseType).then((response: AxiosResponse) => {
      setLoading(false);
      setSaving(false)
      searchStudents({})
      setShowStudentDialog(false);
    }).catch((error) => {
      setLoading(false);
      setShowStudentDialog(false);
      setSaving(false)
      toast.error(error);
    });
  }

  const handleEdit = (values: any) => {
    const responseType = "arraybuffer";
    setSaving(true)
    request.updateFormData(Endpoints.applications + "/" + student.id, values, responseType).then((response: AxiosResponse) => {
      setLoading(false);
      setSaving(false)
      searchStudents({})
      setShowEditDialog(false);
    }).catch((error) => {
      setLoading(false);
      setSaving(false)
      toast.error(error);
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Student applications</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Students" breadcrumbItem="List" />

          <XModal title={"Edit applicant"}
            width={"lg"}
            open={showEditDialog}
            toggle={() => setShowEditDialog(!showEditDialog)}
            onClose={() => setShowEditDialog(false)}>

            <BulkUpload handleSubmit={handleEdit} initialValues={student} isLoading={saving} />

          </XModal>

          <XModal title={"Send SMS"}
            width={"lg"}
            open={showSmsDialog}
            toggle={() => setShowSmsDialog(!showSmsDialog)}
            onClose={() => setShowSmsDialog(false)}>

            <CreateForm
              fields={SmsFields}
              initialValues={{type: "sms", applicants: selectedRows}}
              submitButtonLabel={"SEND"}
              debug={false}
              formData={true}
              handleCustomSubmit={handleSelectedActions}
              onClose={() => setShowSmsDialog(false)} />

          </XModal>

          <XModal title={"Print Interview Letter"}
            width={"lg"}
            open={showInterviewDialog}
            toggle={() => setShowInterviewDialog(!showInterviewDialog)}
            onClose={() => setShowInterviewDialog(false)}>

            <CreateForm
              fields={InterviewFields}
              initialValues={{type: "interview", applicants: selectedRows}}
              submitButtonLabel={"Generate"}
              debug={false}
              formData={true}
              handleCustomSubmit={handleSelectedActions}
              onClose={() => setShowInterviewDialog(false)} />

          </XModal>

          <XModal title={"Print Admission Letter"}
            width={"lg"}
            open={showAdmissionDialog}
            toggle={() => setShowAdmissionDialog(!showAdmissionDialog)}
            onClose={() => setShowAdmissionDialog(false)}>

            <CreateForm
              fields={AdmissionFields}
              initialValues={{type: "admission", applicants: selectedRows}}
              submitButtonLabel={"Generate"}
              debug={false}
              formData={true}
              handleCustomSubmit={handleSelectedActions}
              onClose={() => setShowAdmissionDialog(false)} />

          </XModal>

          <XModal title={"Add applicant"}
            width={"lg"}
            open={showStudentDialog}
            toggle={() => setShowStudentDialog(!showStudentDialog)}
            onClose={() => setShowStudentDialog(false)}>

            <BulkUpload handleSubmit={handleBulkUpload} initialValues={{ autoid: 0 }} isLoading={saving} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Applications
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <>
                        <DropdownItem to="#" onClick={() => setShowStudentDialog(true)}>
                          Add Applicant
                        </DropdownItem>
                      </>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading applicants. Please wait." />}
                  {!loading &&

                    <React.Fragment>
                      <Row className="mb-2">
                        <Col xl="12">
                          <SearchMarks
                            handleSearch={(values: any) => {
                              searchStudents(values);
                            }}
                          />
                        </Col>
                        <ToastContainer />
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th></th><th>Name</th><th>Gender</th><th>Term - Year</th><th>Class</th><th>Phone</th><th>Marks</th><th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {students.map((student: any) => {
                                  return (
                                    <tr key={`student_${student.id}`}>
                                      <td>
                                        <input type="checkbox" checked={selectedRows.includes(student.id)}
                                          onChange={() => handleRowSelection(student.id)} />
                                      </td>
                                      <td>{student.firstname} {student.lastname}</td>
                                      <td>{student.gender}</td>
                                      <td>{student.term} {student.year}</td>
                                      <td>{student.form}</td>
                                      <td>{student.phone}</td>
                                      <td>
                                        <input type="text" name={`marks_${student.id}`} className="form-control" size={2} />
                                      </td>
                                      <td>
                                        <a href="#" onClick={() => {
                                          setStudent(student);
                                          setShowEditDialog(true);
                                        }}>
                                          <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
                                        </a>
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                            <hr/>
                                <a href="#" onClick={() => {handleSelectAll("select")}}>Select All</a> | <a href="#" onClick={() => {handleSelectAll("unselect")}}>Unselect All</a>
                            <hr/>
                            <Row>
                              <Col md={2}><b>With selected:</b> </Col>
                              <Col>
                                <a href="#" className="btn btn-info" onClick={() => {setShowSmsDialog(true)}}><i className="fa fa-sms"></i> SEND SMS</a>&nbsp;&nbsp;&nbsp;
                                <a href="#" className="btn btn-primary"  onClick={() => {setShowInterviewDialog(true)}}><i className="fa fa-print"></i> INTERVIEW LETTER</a>&nbsp;&nbsp;&nbsp;
                                <a href="#" className="btn btn-success"  onClick={() => {setShowAdmissionDialog(true)}}><i className="fa fa-print"></i> ADMISSION LETTER</a>&nbsp;&nbsp;&nbsp;
                                <a href="#" className="btn btn-primary"  onClick={() => {handleSelectedActions({applicants: selectedRows, type: "list"})}}><i className="fa fa-print"></i> PRINT LIST</a>&nbsp;&nbsp;&nbsp;
                              </Col>
                            </Row>
                            <br />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default StudentApplications;