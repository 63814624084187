import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from "reactstrap";
import { Settings } from "../../utils/Settings";


const Post = (props: any) => {
  const { data } = props;

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-transparent">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h5 className="card-title mb-0">Posts</h5>
            </div>
          </div>
        </CardHeader>
        <div>
        {data.map((post: any) => {
            return (
              <Card>
                <CardBody>
                  {post.title}
                </CardBody>
              </Card>
            )
          })}
        </div>
      </Card>
    </React.Fragment>
  );
};

export default Post;
