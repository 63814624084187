import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import { Endpoints } from "../../utils/Endpoints";
import BulkUpload from "./upload";
import Logo from "../../assets/images/logo.png"
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { formattedDate } from "../../utils/Helpers";

const Posts = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [posts, setPosts] = useState<any>([]);
  const [showPostDialog, setShowPostDialog] = useState<boolean>(false);

  const request = new ApiCore();

  useEffect(() => {
    searchPosts({})
  }, []);

  const searchPosts = (values: any) => {
    values.per_page = 1000;
    request
      .get(Endpoints.posts, { params: values }, values.excel && values.excel == 1 ? "arraybuffer" : "json").then((response: AxiosResponse) => {
        setPosts(response.data);
        setLoading(false);
      });
  }

  const handleBulkUpload = (values: any) => {
    setSaving(true)
    const responseType = "arraybuffer";
    request.createFormData(Endpoints.posts, values, responseType).then((response: AxiosResponse) => {
      setSaving(false);
      setShowPostDialog(false);
      searchPosts({});
    }).catch((error) => {
      setSaving(false);
      toast.error(error);
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Noticeboard</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Students" breadcrumbItem="List" />

          <XModal title={"Create post"}
            width={"lg"}
            open={showPostDialog}
            toggle={() => setShowPostDialog(!showPostDialog)}
            onClose={() => setShowPostDialog(false)}>

            <BulkUpload handleSubmit={handleBulkUpload} initialValues={{ title: "", content: "" }} isLoading = {saving} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Noticeboard
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <>
                        <DropdownItem to="#" onClick={() => setShowPostDialog(true)}>
                          Add Post
                        </DropdownItem>
                      </>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading posts. Please wait." />}
                  {!loading &&
                    <Row>
                      {posts.map((post: any) => {
                        return (
                          <Col md={4}>
                            <Card>
                              <img src={post.coverUrl} className="card-img-top" />
                              <CardBody>
                                <Row>
                                  <Col md={2}>
                                    <img src={Logo} className="img-round"/>
                                  </Col>
                                  <Col>
                                  <p><h5 className="card-title">{post.title}</h5></p>
                                  <p className="txt-caption"><b>Created on:</b> {formattedDate(post.created_at)}</p>
                                  <a href={`/posts/${post.id}`} className="btn btn-primary">View Details</a>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      })}
                    </Row>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Posts;