import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

//import Breadcrumbs
import Breadcrumbs from "../../components/common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";

import Student from "./Student";
import Widget from "./Widget";
import VisitorSource from "./VisitorSource";
import TrafficSource from "./TrafficSource";
import Channels from "./Channels";
import { AxiosResponse } from "axios";
import { ApiCore } from "../../helpers/api_helper";
import { Endpoints } from "../../utils/Endpoints";
import { Loading } from "../../components/common/Loading";
import { amountToCurrency } from "../../utils/Helpers";
import Post from "./Post";

const Dashboard = () => {
    const request = new ApiCore();
    const [loading, setLoading] = useState<boolean>(true);
    const [students, setStudents] = useState<any>([]);

    const [loadingPosts, setLoadingPosts] = useState<boolean>(true);
    const [posts, setPosts] = useState<any>([]);

    useEffect(() => {

        request.get(Endpoints.students, {})
            .then((response: AxiosResponse) => {
                setStudents(response.data);
                setLoading(false);

            })
            .catch((error) => {
                console.error(error);
            });

        request.get(Endpoints.posts, {})
            .then((response: AxiosResponse) => {
                setPosts(response.data);
                setLoadingPosts(false);

            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Home</title>
                </MetaTags>

                <Container fluid>
                    <>
                        <Row>
                            <Col lg={5}>
                                {loading && <Loading label="Please wait..." />}
                                {!loading &&
                                    <Student data={students} />
                                }
                            </Col>
                            <Col lg={7}>
                            {loadingPosts && <Loading label="Please wait..." />}
                                {!loadingPosts &&
                                    <Post data={posts} />
                                }
                            </Col>
                        </Row>
                    </>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;